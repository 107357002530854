import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Screens/Login";
import Dashboard from "./Screens/Dashboard";
import TransSend from "./Screens/TransSend";
import TransStatus from "./Screens/TransStatus";
import Deposits from "./Screens/Deposits";
import CreateUser from "./Screens/CreateUser";
import Support from "./Screens/Support";
import UpdateUser from "./Screens/UpdateUser";
import FindUser from "./Screens/FindUser";
import RequestFloat from "./Screens/RequestFloat";
import ManageCash from "./Screens/ManageCash";
import Commissions from "./Screens/Commissions";
import InnerBank from "./Screens/InnerBank";
import Reports from "./Screens/Reports";
import Nav from "./Components/Nav";
import Logout from "./Screens/Logout";
import DepositsComp from "./Components/DepositsComp";
import MakeDeposit from "./Screens/MakeDeposit";
import MakeTrans from "./Screens/MakeTrans";
import ComTrans from "./Screens/ComTrans";
import Cashout from "./Screens/Cashout";
import ChangeCurrency from "./Screens/ChangeCurrency";
import Settings from "./Screens/Settings";
import CashinvsCashout from "./Components/CashinvsCashout";
import ConvertCTFloat from "./Screens/ConvertCTFloat";
import CreateBeneficiary from "./Components/CreateBeneficiary";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route without Nav */}
        <Route path="/" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        
        {/* Routes with Nav */}
        <Route
          path="/*"
          element={
            <>
              <Nav />
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/send" element={<TransSend />} />
                <Route path="/status" element={<TransStatus />} />
                <Route path="/deposits" element={<Deposits />} />
                <Route path="/create-user" element={<CreateUser />} />
                <Route path="/support" element={<Support />} />
                <Route path="/update" element={<UpdateUser />} />
                <Route path="/find" element={<FindUser />} />
                <Route path="/float" element={<RequestFloat />} />
                <Route path="/manage-cash" element={<ManageCash />} />
                <Route path="/manage-commission" element={<Commissions />} />
                <Route path="/innerBank" element={<InnerBank />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/makeDeposit" element={<MakeDeposit/>} />
                <Route path="/makeTrans" element={<MakeTrans/>} />
                <Route path="/comtrans" element={<ComTrans/>} />
                <Route path="/cashout" element={<Cashout/>} />
                <Route path="/convert" element={<ChangeCurrency/>} />
                <Route path="/settings" element={<Settings/>} />
                <Route path="/cintofloat" element={<ConvertCTFloat/>} />
                <Route path="/create-beneficiary" element={<CreateBeneficiary/>} />
              </Routes>
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
